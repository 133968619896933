// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$parcours-mrh-b2b-primary: mat-palette($mat-indigo);
$parcours-mrh-b2b-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$parcours-mrh-b2b-warn: mat-palette($mat-red);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$parcours-mrh-b2b-theme: mat-light-theme(( color: ( primary: $parcours-mrh-b2b-primary, accent: $parcours-mrh-b2b-accent, warn: $parcours-mrh-b2b-warn, )));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($parcours-mrh-b2b-theme);
body {
    margin: 0px;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../src/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.iti {
    display: block !important;
}

.iti .dropdown-menu.country-dropdown {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: #c7cace;
    margin-top: -1px;
}

.iti .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    margin-left: 0;
    width: 244px;
    max-height: 170px;
}

.iti__flag-container.open+input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.iti .search-container input {
    font-size: 14px;
    border-color: #c7cace;
    border-radius: 0;
    padding: 5px 10px;
}

.iti .search-container input:focus {
    outline: none;
}

@media screen and (max-width: 479px) {
    .iti .iti__country-list {
        width: 88.3vw;
    }
}

ngx-intl-tel-input input {
    height: 44px;
    margin-bottom: 20px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #c7cace;
    border-radius: 4px;
    font-size: 18px;
    width: 100%;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
    border: 1px solid #c0392b;
    border-width: 2px;
}

ngx-intl-tel-input input:hover {
    border-color: #000000da;
    border-width: 2px;
}

ngx-intl-tel-input input:focus {
    outline: none !important;
    border-color: #292371;
    box-shadow: 0 0 0 0 #292371;
    border-width: 2px;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
    opacity: 0.5;
    background: white;
    border: 1px solid #8080802e;
}